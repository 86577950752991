
function SolutionMainView() {
  return (
    <div >
    
        로그인한 사람만들어올수있는 SolutionMainView페이지이다.
     
    </div>
  );
}

export default SolutionMainView;