export const PATH_APP = {
  root: '/',
  login: {
    root: '/login',
  },
  document: {
    root: '/document',
  },
  application: {
    root: '/application',
  },
  solution: {
    root: '/solution',
  },
  price: {
    root: '/price',
  },
}
