import {increaseAsync, decreaseAsync} from '../../redux/reducer/PokemonReducer'
import {useDispatch, useSelector} from 'react-redux'
import {Typography} from '@mui/material'
import './HomeMainView.css'
function HomeMainView() {
  const dispatch = useDispatch()
  const {number} = useSelector((state) => state.PokemonReducer)
  return (
    <div>
      <div
        className='banner'
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          alignSelf: 'center',
          padding: '5rem 5rem 5rem 0',
          position: 'relative',
        }}>
        <div className='custom-shape-divider-bottom-1634653438'>
          <svg
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1200 120'
            preserveAspectRatio='none'>
            <path
              d='M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z'
              className='shape-fill'></path>
          </svg>
        </div>
        <div style={{alignSelf: 'center'}}>
          <Typography
            sx={{color: 'white', fontSize: '2rem', fontFamily: 'qweqwe'}}>
            베어소프트와 함께
          </Typography>
          <Typography sx={{color: 'white', fontSize: '2rem'}}>
            다양한 서비스를 만들어보아요!
          </Typography>
          <Typography> 이것은 메인홈페이지이다.</Typography>
        </div>
        <div style={{display: 'flex', height: '80%'}}>
          {/* <img
            style={{marginRight: '2rem', height: '100%', objectFit: 'contain'}}
            src={mobilepic}
            alt='사진없음'
          />
          <img
            style={{marginRight: '2rem', height: '100%', objectFit: 'contain'}}
            src={mobilepic}
            alt='사진없음'
          />
          <img
            style={{marginRight: '2rem', height: '100%', objectFit: 'contain'}}
            src={mobilepic}
            alt='사진없음'
          /> */}
        </div>
      </div>
      <div style={{padding: '5rem'}}>
        <Typography> {number}</Typography>
        <button onClick={() => dispatch(increaseAsync())}>증가</button>
        <button onClick={() => dispatch(decreaseAsync())}>감소</button>
        <Typography sx={{fontSize: '2rem', color: '#7e6161'}}>
          회사 소개
        </Typography>
        <div style={{border: '1px solid #e7c9c9', width: '15rem'}} />
        <Typography sx={{fontSize: '2rem', color: '#7e6161'}}>
          파트너 소개
        </Typography>
        <div style={{border: '1px solid #e7c9c9', width: '15rem'}} />
        <Typography sx={{fontSize: '2rem', color: '#7e6161'}}>
          {' '}
          비지니스 소개
        </Typography>
        <div style={{border: '1px solid #e7c9c9', width: '15rem'}} />
      </div>
    </div>
  )
}

export default HomeMainView
