import PokemonReducer from './PokemonReducer'
import AuthJwt from './AuthJwt'
import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated'],
}
const rootReducer = combineReducers({
  PokemonReducer,
  authJwt: persistReducer(authPersistConfig, AuthJwt),
})

export default rootReducer
