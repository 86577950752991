function Footer() {
    return (
        <div style={{
            height:'7rem',
            width:'100%',
            position:'fixed',
            bottom:'0px',
            backgroundColor:'#0000001a',
           color:'white',
           fontWeight:'bold'
        }}>
           <p> (주) 베어소프트</p>
        </div>
    )
}

export default Footer
