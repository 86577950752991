import {Typography} from '@mui/material'
import {useHistory} from 'react-router'
import MenuList from './MenuList'
import {useDispatch, useSelector} from 'react-redux'

import {logout} from '../../../redux/reducer/AuthJwt'
function TopBar() {
  const history = useHistory()
  const dispatch = useDispatch()
  const {isAuthenticated} = useSelector((state) => state.authJwt)

  const handleLogout = () => {
    try {
      dispatch(logout())
      history.push('/login')
    } catch (error) {
      console.error(error)
    }
  }

  const menuList = [
    'My Applications',
    'Solution',
    'Documents',
    'Pricing',
    'Login',
    'Home',
  ]

  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <Typography
        sx={{
          color: '#186ad1',
          fontSize: '2.5rem',
          fontWeight: 'bold',
          padding: '1rem',
        }}>
        BearSoft
      </Typography>
      <div style={{display: 'flex', marginRight: '2rem'}}>
        {menuList.map((value, index) => (
          <MenuList key={index} value={value} index={index} />
        ))}
        {isAuthenticated && <button onClick={handleLogout}>로그아웃</button>}

        <div>{isAuthenticated && <div>로그인중</div>}</div>
      </div>
    </div>
  )
}

export default TopBar
