import * as React from 'react'
import {Redirect} from 'react-router-dom'
import {PATH_APP} from '../../routes/paths'
import {useSelector} from 'react-redux'
import {Route} from 'react-router-dom'
// ----------------------------------------------------------------------

const PrivateRoute = ({component, ...rest}) => {
  const {isAuthenticated} = useSelector((state) => state.authJwt)
  const routeComponent = (props) =>
    isAuthenticated ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={PATH_APP.login.root} />
    )
  return <Route {...rest} render={routeComponent} />
}

export default PrivateRoute
