const INCREASE = 'counter/INCREASE'
const DECREASE = 'counter/DECREASE'

export const increase = () => ({type: INCREASE}) //액션함수를 export하는 이유는 ,컨테이너에서 import하기 위함임
export const decrease = () => ({type: DECREASE})

export const increaseAsync = () => (dispatch) => {
  dispatch(increase())
}

export const decreaseAsync = () => (dispatch) => {
  dispatch(decrease())
}

const initialState = {
  number: 0,
}

const PokemonReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREASE:
      return {
        //리듀서에서 const initialState={ number : 0 } 로부터  업데이트된 객체가 반환됨
        number: state.number + 1,
      }
    case DECREASE:
      return {
        number: state.number - 1,
      }

    default:
      return state
  }
}

export default PokemonReducer
