function ApplicationsMainView() {
  return (
    <div >
  
      로그인한사람만들어올수있는 ApplicationsMainView페이지이다!!!!!!.
  
    </div>
  );
}

export default ApplicationsMainView;