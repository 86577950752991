import './App.css'
import {renderRoutes} from './routes'
import {JwtProvider} from './components/Auth/JwtProvider'

function App() {
  return (
    <div style={{maxWidth: '3000px', margin: '0 auto', height: '100vh'}}>
      <JwtProvider>{renderRoutes()}</JwtProvider>
    </div>
  )
}

export default App
