
function PricingMainView() {
  return (
    <div >
    
        PricingMainViw페이지이다.
     
    </div>
  );
}

export default PricingMainView;