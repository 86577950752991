import * as React from 'react'
import {login} from '../../redux/reducer/AuthJwt'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
function LoginView() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loginData, setLoginData] = React.useState({email: '', password: ''})

  const clickButton = () => {
    dispatch(
      login(loginData, () => {
        history.push('/')
      })
    )
  }
  return (
    <div>
      로그인페이지이다!!!!!!.
      <input
        type='text'
        id='email'
        name='email'
        required
        onChange={(e) => {
          setLoginData({...loginData, email: e.target.value})
        }}></input>
      <input
        type='text'
        id='password'
        name='password'
        required
        onChange={(e) => {
          setLoginData({...loginData, password: e.target.value})
        }}></input>
      <button onClick={clickButton}>로그인</button>
    </div>
  )
}

export default LoginView
