import {applyMiddleware, createStore, Store, compose} from 'redux'

import rootReducer from './reducer'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

const store = createStore(
  rootReducer,
  compose(composeWithDevTools(applyMiddleware(thunk)))
)

export default store
