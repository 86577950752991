import * as React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import {PATH_APP} from './paths'
// ----------------------------------------------------------------------
import HomeMainView from '../views/Home/HomeMainView'
import LoginView from '../views/Auth/LoginView'
import DocumentMainView from '../views/Documents/DocumentMainView'
import ApplicationsMainView from '../views/Applications/ApplicationsMainView'
import SolutionMainView from '../views/Solution/SolutionMainView'
import PricingMainView from '../views/Pricing/PricingMainView'
import TopBar from '../views/Layout/TopBar'
import Footer from '../views/Layout/Footer'
import AuthProtects from '../components/Auth/AuthProtects'

function renderRoutes() {
  return (
    <BrowserRouter>
      <React.Suspense
        fallback={
          <div
            style={{backgroundColor: 'black', height: '100%', width: '100%'}}>
            로딩중..
          </div>
        }>
        <TopBar />
        <Switch>
          <Route exact={true} path={PATH_APP.root} component={HomeMainView} />
          <Route
            exact={true}
            path={PATH_APP.login.root}
            component={LoginView}
          />
          <Route
            exact={true}
            path={PATH_APP.document.root}
            component={DocumentMainView}
          />
          <Route
            exact={true}
            path={PATH_APP.price.root}
            component={PricingMainView}
          />
          <AuthProtects
            exact={true}
            path={PATH_APP.solution.root}
            component={SolutionMainView}
          />
          <AuthProtects
            exact={true}
            path={PATH_APP.application.root}
            component={ApplicationsMainView}
          />
        </Switch>
        <Footer />
      </React.Suspense>
    </BrowserRouter>
  )
}

export {renderRoutes}
