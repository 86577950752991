import {Typography, Button} from '@mui/material'
import {useHistory} from 'react-router'
const link = ['application', 'solution', 'document', 'price', 'login', '']

function MenuList({index, value}) {
  const history = useHistory()
  return (
    <Button
      onClick={() => {
        history.push(`/${link[index]}`)
      }}>
      <Typography sx={{color: '#898484'}}> {value}</Typography>
    </Button>
  )
}

export default MenuList
