import * as React from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {getInitialize} from '../../redux/reducer/AuthJwt'

//토큰 정보에 대한 조기화가 이루어짐, 새로운 브라우저를 열거나, 새로고침을 누르거나 할때----------------------------------------------------------------------

const JwtProvider = ({children}) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getInitialize())
  }, [dispatch])

  return <>{children}</>
}

JwtProvider.propTypes = {
  children: PropTypes.node,
}

export {JwtProvider}
