//import jwtDecode from 'jwt-decode'
//import {serverApiCallPost} from '../../api/serverCall'

const STARTLOADING = 'AuthJwt/STARTLOADING'
const GETINIT = 'AuthJwt/GETINIT'
const LOGINSUCCESS = 'AuthJwt/LOGINSUCCESS'
const RESISTERSUCCESS = 'AuthJwt/RESISTERSUCCESS'
const LOGOUTSUCCESS = 'AuthJwt/LOGOUTSUCCESS'

const startLoading = () => ({type: STARTLOADING}) //액션함수를 export하는 이유는 ,컨테이너에서 import하기 위함임
const getInit = (data) => ({type: GETINIT, payload: data})
const loginSuccess = (user) => ({type: LOGINSUCCESS, payload: user})
const resisterSuccess = (user) => ({type: RESISTERSUCCESS, payload: user})
const logoutSuccess = () => ({type: LOGOUTSUCCESS})

const isValidToken = (accessToken) => {
  if (!accessToken) return false

  // const decoded = jwtDecode(accessToken)
  // const currentTime = Date.now() / 1000

  // return decoded.exp > currentTime
  return true
}

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
  } else {
    localStorage.removeItem('accessToken')
  }
}
// ----------------------------------------------------------------------

export const login = ({email, password}, callback) => {
  const requestData = {username: email, password: password}
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading())
    try {
      // serverApiCallPost({
      //   link: `/authenticate`, //로그인토큰발급 api 가상으로 나줄에쓸용도임
      //   body: requestData,
      //   loginRequest: true,
      // }).then((response) => {
      //   if (response.data.token) {
      //     //console.log(response.data.token)
      //     //eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MjAsInN1YiI6ImVlYjk2IiwiaWF0IjoxNjM0NjI0MzA2LCJleHAiOjE2MzQ2NjAzMDZ9.k1CIz3ctSGMTAGfeCL29isRiU44gbEPnqTL_1alB2XDEcp1vuNciBMrtlKEXzh7d1L4Fag6iB6-gBIAPefh1QA
      //     const decoded = jwtDecode(response.data.token)
      //     //console.log(decoded)
      //     //{id: 20, sub: 'eeb96', iat: 1634624518, exp: 1634660518}
      //     setSession(response.data.token)
      //     const totalUser = {
      //       ...decoded,
      //       token: response.data.token,
      //     }
      //     dispatch(loginSuccess({user: totalUser}))
      //   }
      // })
      //원래 토큰은 api 호출을 통해 받아야하지만 일단 flow처리를 위해 fake로 무조건 로그인 처리되게처리해놓음,
      const responseToken =
        'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MjAsInN1YiI6ImVlYjk2IiwiaWF0IjoxNjM0NjI0MzA2LCJleHAiOjE2MzQ2NjAzMDZ9.k1CIz3ctSGMTAGfeCL29isRiU44gbEPnqTL_1alB2XDEcp1vuNciBMrtlKEXzh7d1L4Fag6iB6-gBIAPefh1QA'
      const decoded = {id: 20, sub: 'eeb96', iat: 1634624518, exp: 1634660518}
      setSession(responseToken)
      const totalUser = {
        ...decoded,
        token: responseToken,
      }
      dispatch(loginSuccess({user: totalUser}))
      callback()
    } catch (error) {
      console.log(error)
    }
  }
}
export const logout = () => {
  return async (dispatch) => {
    setSession(null)
    dispatch(logoutSuccess())
  }
}
// ----------------------------------------------------------------------
//회원가입관련이다.
// export const register = ({email, password, firstName, lastName}:any) => {
//   return async (dispatch:Dispatch) => {
//     const response = await axios.post('/api/account/register', {
//       email,
//       password,
//       firstName,
//       lastName,
//     })
//     const {accessToken, user} = response.data

//     window.localStorage.setItem('accessToken', accessToken)
//     dispatch(resisterSuccess({user}))
//   }
// }
///////

export const getInitialize = () => {
  return async (dispatch) => {
    dispatch(startLoading())

    try {
      const accessToken = window.localStorage.getItem('accessToken')

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken)
        // const decoded = jwtDecode(accessToken)
        const decoded = {
          id: 20,
          sub: 'eeb96',
          iat: 1634624518,
          exp: 1634660518,
        }
        const totalUser = {
          ...decoded,
          token: accessToken,
        }

        dispatch(
          getInit({
            isAuthenticated: true,
            user: totalUser,
          })
        )
      } else {
        dispatch(
          getInit({
            isAuthenticated: false,
            user: {},
          })
        )
        setSession(null)
      }
    } catch (error) {
      console.error(error)
      dispatch(
        getInit({
          isAuthenticated: false,
          user: {},
        })
      )
      setSession(null)
    }
  }
}

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {},
}

const AuthJwt = (state = initialState, action) => {
  switch (action.type) {
    case STARTLOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GETINIT:
      return {
        isLoading: false,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
      }
    case LOGINSUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      }

    case RESISTERSUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      }

    case LOGOUTSUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      }

    default:
      return state
  }
}

export default AuthJwt
